/*** button ******/
button{
  padding: 0px 14px;
}
.with-bg,
.with-bg:hover,
.with-bg:focus {
  font-size: 16px !important;
  color: rgb(60, 61, 92) !important;
  background-color: rgb(81, 226, 245) !important;
  border: 1px solid rgb(81, 226, 245) !important;
  height: 38px !important;
  border-radius: 8px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 5px 15px !important;
  font-weight: 700;
  transition: all .5s;
}
button{
  transition: all ease .5s;
}
.with-bg:hover{
  box-shadow: 0 0 50px 0 hsl(0deg 0% 100% / 40%);
  background: #22cee4 !important;
}
button:hover{
  box-shadow: 0 0 50px 0 hsl(0deg 0% 100% / 40%);
}
button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.with-bg i {
  font-size: 30px;
  margin-right: 5px;
}
.without-bg,
.without-bg:hover,
.without-bg:focus {
  background-color: transparent !important;
  border: none !important;
  color: rgb(255, 255, 255) !important;
  padding: 0px !important;
}
/****** Input ***********/
input:hover,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}
.form-field {
  position: relative;
}
.form-field i {
  position: absolute;
  top: 15px;
  left: 20px;
  font-size: 24px;
  color: rgb(81, 226, 245);
}
.form-field input, .form-field input:focus, .form-field input:hover {
  border-width: 1px;
  height: 48px;
  border-color: rgb(54, 57, 67);
  border-style: solid;
  border-radius: 8px;
  background-color: rgb(31, 35, 48);
  padding: 5px 12px 5px 58px;
  color: white;
}
.form-field input::placeholder{
  background-color: rgb(12, 14, 35); 
}
.form-control:disabled, .form-control[readonly], .form-control:disabled:hover {
  background-color: rgb(26 29 41) !important; 
  color: white !important;
  opacity: 1;
  border: 0.4px solid rgb(54, 57, 67) !important;
}
.form-field{
  margin-bottom: 15px;
}
input:focus {
  outline: none;
  box-shadow: none;
}
.form-field.no-icon input {
  padding: 5px 12px;
}
/********* tabs css ************/
.nav-tabs {
  border: none !important;
  margin-bottom: 48px !important;
  margin-right: 30px !important;
}
.nav-tabs li button {
  background-color: transparent !important;
  border: none !important;
  padding: 0px !important;
  font-size: 16px !important;
  color: rgb(255, 255, 255) !important;
  padding-bottom: 10px !important;
}
.nav-tabs li .nav-link.active {
  color: rgb(81, 226, 245) !important;
  border-bottom: 2px solid rgb(81, 226, 245) !important;
  border-radius: 0px !important;
}
.nav-tabs {
  border: none !important;
  margin-bottom: 48px !important;
}
.nav-tabs li {
  margin-right: 50px !important;
}

/********* toggle ************/
.toggle-section input {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.toggle-section label {
  cursor: pointer;
  text-indent: -9999px;
  width: 41px;
  height: 21px;
  display: block;
  border-radius: 100px;
  position: relative;
  margin-bottom: 0px;
  margin-left: auto;
  border-radius: 6px;
  background-color: #34363f;
}

.toggle-section label:after {
  content: "";
  position: absolute;
  top: 4.1px;
  left: 4px;
  width: 13px;
  height: 13px;
  background: #51e2f5;
  border-radius: 4px;
  transition: 0.5s;
}

.toggle-section input:checked + label {
  background-color: rgb(81, 226, 245);
}

.toggle-section input:checked + label:after {
  left: calc(100% - 4px);
  transform: translateX(-100%);
  background: rgb(12, 14, 35);
}

.toggle-section label:active:after {
  width: 6px;
}
.toggle-section.dark-toggle input:checked + label {
  background: rgb(12, 14, 35);
}

.toggle-section.dark-toggle input:checked + label:after {
  background-color: rgb(81, 226, 245);
}


h6.error-msg {
  padding: 0;
  margin: 0 !important;
  font-size: 14px !important;
  color: #ff4747 !important;
  margin-bottom: 12px !important;
}
@media screen and (max-width: 1220px) {
 .nav-tabs {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    margin: 0px 0px 20px;
  }
}

@media screen and (max-width: 767px) {
  .form-field i {
    top: 12px;
    left: 10px;
    font-size: 20px;
  }
  input::placeholder {
    font-size: 13px;
  }
  input {
    font-size: 13px !important;
  }
  .form-field input, .form-field input:focus, .form-field input:hover{
    padding: 5px 12px 5px 40px;
  }
}
@media screen and (max-width: 992px) {
 .nav-tabs {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    margin: 20px 0px;
  }
}
