@import url("https://fonts.googleapis.com/css2?family=Courgette&family=Exo+2:wght@200;300;400;500&family=Lobster&family=Poppins:wght@100;200;300;400&display=swap");
.winning-popup {
  color: #fff;
  z-index: 9999999999;
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  top: 50%;
  left: 50%;
  font-size: 40px;
  color: #f7f7f7;
  padding: 41px 20px;
  /* max-width: 600px; */
  width: 100%;
  height: 100%;
  text-align: center;
  /* background-image: linear-gradient(90deg,rgba(235,41,88,0),#eb2958,rgba(235,41,88,0)); */
  animation: neon1 0.5s ease-in-out;
  /* background: rgb(9,9,121); */
  /* background: radial-gradient(
    circle,
    #eb2958 12%,
    rgba(235, 41, 89, 0.2) 52%,
    rgb(235 41 88 / 0%) 100%,
    rgba(235, 41, 89, 0) 100%
  ); */
  background: radial-gradient(
    circle,
    #77006c96 12%,
    rgba(235, 41, 89, 0.2) 52%,
    rgb(235 41 88 / 0%) 100%,
    rgba(235, 41, 89, 0) 100%
  );
  animation-fill-mode: forwards;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.win-case {
  background: radial-gradient(
    circle,
    #00774596 12%,
    rgb(3 111 3 / 29%) 52%,
    rgb(235 41 88 / 0%) 100%,
    rgba(235, 41, 89, 0) 100%
  );
}
.popup-wrapper {
  position: fixed;
  z-index: 999999;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  height: 100%;
  width: 100%;
}
.popup-wrapper:before {
  content: "";
  position: absolute;
  background: hsl(0deg 0% 0% / 84%);
  height: 100%;
  width: 100%;
  z-index: 999999;
}
@keyframes neon1 {
  from {
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}
.winning-popup p {
  margin: 0;
  font-weight: 500;
  font-size: 45px;
  font-family: "Courgette", cursive;
  font-family: "Exo 2", sans-serif;
  font-family: "Lobster", cursive;
}

.winning-popup h6,
.winning-popup h1 {
  margin: 0;
  font-size: 55px;
  font-family: "Courgette", cursive;
  font-family: "Exo 2", sans-serif;
  font-family: "Lobster", cursive;
  text-shadow: 0px 6px 0px black;
}
.winning-popup h1 {
  margin-top: 0px;
}
.winning-popup h6 {
  font-size: 140px;
  color: #ffeb00;
  font-weight: 700;
}

.coins:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: red;
  background-position: center;
  background: url("../../assets/images/tower/car-ray.png");
  left: 0px;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: -1;
  animation: bubble 20s infinite;
}
@keyframes bubble {
  0% {
    transform: rotate(0deg);
    opacity: 0.8;
  }

  50% {
    transform: rotate(180deg);
    opacity: 1;
  }

  100% {
    transform: rotate(360deg);
    opacity: 0.8;
  }
}
.coins-img img {
  height: 130px;
  margin-top: -39px;
  z-index: -1;
}
.coins {
  opacity: 0.3;
  max-width: 900px;
  width: 100%;
  margin: 0px auto;
  max-height: 727px;
  height: 100%;
  position: absolute;
}

/******** deposit popup ************/
.deposit-popup .modal-dialog {
  max-width: 800px;
  width: 100%;
}
.deposit-popup .modal-content {
  margin: 0;
  background-position: right;
}
.deposit-popup .desit-type-boxes {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}
.deposit-popup .depsoit-popup-content h6 {
  margin: 0;
  font-size: 24px;
  font-family: "Poppins";
  color: rgb(162, 163, 169);
  font-weight: bold;
}
.deposit-popup .deposit-boxes {
  width: 49%;
  text-align: center;
  border-width: 0.5px;
  border-color: rgb(47, 49, 56);
  border-style: solid;
  border-radius: 8px;
  background-color: rgb(15, 17, 36);
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.deposit-popup .deposit-boxes img {
  width: 105px;
}

.deposit-popup .depsoit-popup-content {
  padding: 30px 40px;
}
.deposit-popup .deposit-boxes h6 {
  margin-bottom: 22px;
}
.deposit-popup .coin-boxes {
  border-width: 0.5px;
  border-color: rgb(47, 49, 56);
  border-style: solid;
  border-radius: 8px;
  background-color: rgb(15, 17, 36);
  height: 150px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.deposit-popup .coin-boxes img {
  width: 60px;
}
.deposit-popup .coin-boxes p {
  margin: 0;
  font-size: 18px;
  font-family: "Poppins";
  color: rgb(162, 163, 169);
  margin-top: 20px;
}
.deposit-popup .deposit-box-list {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 15px;
  margin-top: 40px;
}

/***** deposit robox popup **********/
.deposit-robox-popup h6 {
  font-size: 24px;
  font-family: "Poppins";
  color: rgb(255, 255, 255);
  font-weight: bold;
}
.deposit-robox-popup .deposit-robox-content {
  padding: 35px 40px;
}
.deposit-robox-popup .deposit-robox-content label {
  font-size: 16px;
  font-family: "Poppins";
  color: rgb(162, 163, 169);
}
.deposit-robox-popup .modal-dialog {
  max-width: 800px;
  width: 100%;
}
.deposit-robox-popup .form-field i {
  top: 42px;
}
.deposit-robox-popup .modal-content {
  margin: 0;
  min-height: 475px;
}
.deposit-robox-popup .input-section {
  max-width: 80%;
  position: relative;
}
.deposit-robox-popup .input-section button {
  position: absolute;
  right: 5px;
  top: 36.4px;
  margin-top: 0px !important;
}
.deposit-robox-popup p {
  max-width: 60%;
  font-size: 14px;
  font-family: "Poppins";
  color: rgb(255, 255, 255);
  margin-top: 22px;
}
.deposit-robox-popup .input-section button {
  margin-top: 8px;
}
.deposit-robox-popup .input-section {
  margin-top: 35px;
}

.deposit-robox-popup .deposit-robox-content h6 {
  font-size: 24px;
  font-family: "Poppins";
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.deposit-robox-popup .deposit-robox-content h6 span {
  color: #51e2f5;
  margin-left: 8px;
}
.deposit-robox-popup .close-popup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 0px;
  position: relative;
  top: 0;
  right: 0;
}

/******** deposit via popup **********/
.deposit-via-popup .left-deposit-via {
  width: calc(100% - 220px);
}
.deposit-via-popup .right-deposit-via {
  width: 182px;
  display: flex;
  justify-content: flex-end;
}
.deposit-via-popup .right-deposit-via img {
  width: fit-content;
  object-fit: contain;
  height: 160px;
}
.deposit-via-popup .input-section {
  max-width: 100%;
  position: relative;
}
.deposit-via-popup p {
  max-width: 100%;
  margin: 0;
}
.deposit-via-popup .input-section button {
  top: 4.4px;
}
.deposit-via-popup .form-field i {
  top: 15px;
}
.deposit-via-popup .qr-content {
  margin-top: 38px;
}
.deposit-via-popup p span {
  color: #51e2f5;
}
.deposit-via-popup p span {
  color: #51e2f5;
}
.deposit-via-popup .enter-value {
  display: flex;
  align-items: center;
}
.deposit-via-popup .enter-value .form-field {
  margin: 40px 0px;
}
.deposit-via-popup .enter-value input {
  text-align: right;
}
.deposit-via-popup .enter-value span {
  margin: 40px 8px;
}
.deposit-via-popup .deposit-via-sectionn {
  display: flex;
  justify-content: space-between;
}
.deposit-via-popup .form-field i {
  top: 12px;
  right: 5px;
}

/******* credit popup ************/
.deposit-via-creditcard .deposit-credit-content {
  padding: 35px 90px;
  text-align: center;
}
.deposit-via-creditcard .deposit-credit-content p {
  width: 100%;
  max-width: 100%;
}
.deposit-via-creditcard .kyc-verification-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.deposit-via-creditcard .kyc-verification-section .kyc-box {
  border-width: 0.5px;
  border-color: rgb(47, 49, 56);
  border-style: solid;
  border-radius: 8px;
  background-color: rgb(15, 17, 36);
  width: 300px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.deposit-via-creditcard .kyc-verification-section .kyc-box p {
  font-size: 16px;
  color: rgb(255, 255, 255);
  margin: 0px;
}

.deposit-via-creditcard .kyc-verification-section .kyc-box p span {
  color: #ffd34c;
}
.deposit-via-creditcard .deposit-via-sectionn p {
  margin: 0;
  font-size: 14px;
  color: rgb(255, 255, 255);
}

.deposit-via-creditcard .deposit-via-sectionn h6 {
  margin-bottom: 35px;
}

.deposit-via-creditcard .deposit-via-sectionn h6 span {
  color: #4cd1e3;
}

.deposit-via-creditcard .form-check {
  display: flex;
  justify-content: center;
  margin: 18px 0px;
}

.deposit-via-creditcard .form-check label span {
  color: #4cd1e3;
}
.deposit-via-creditcard .credit-btns {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.deposit-via-creditcard .credit-btns button:first-child {
  margin-bottom: 22px;
}
.deposit-via-creditcard .credit-btns button:last-child {
  border-width: 0.5px !important;
  border-color: rgb(47, 49, 56) !important;
  border-style: solid !important;
  border-radius: 8px !important;
  background-color: rgb(15, 17, 36) !important;
  color: white !important;
  min-width: 188px;
}
.deposit-via-creditcard .form-check label {
  font-size: 14px;
  color: rgb(255, 255, 255);
  margin: 0;
}

/******** deposit gift popup *********/
.deposit-giftcard-popup p {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0;
}
.deposit-giftcard-popup .gift-box {
  border-radius: 8px;
  background-color: rgb(0, 0, 0);
  height: 90px;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.deposit-giftcard-popup .gift-box .gift-right p {
  transform: rotate(269deg);
}
.deposit-giftcard-popup .gift-box .gift-right p {
  white-space: nowrap;
  font-size: 12px;
  color: rgb(255, 255, 255);
  position: absolute;
  bottom: 0px;
}
.deposit-giftcard-popup .gift-box .gift-right img {
  transform: rotate(90deg);
  margin: 8px 8px;
}
.deposit-giftcard-popup .gift-box .gift-right p span {
  color: rgb(81, 226, 245);
}
.deposit-giftcard-popup .gift-right {
  border-width: 0.5px;
  border-color: rgb(0, 0, 0);
  border-style: dashed;
  background-color: rgb(20, 70, 77);
  width: 32px;
  height: 90px;
  position: relative;
  z-index: 99;
}
.deposit-giftcard-popup .gift-card-section {
  margin-top: 45px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 15px;
}

.deposit-giftcard-popup .deposit-robox-content .gift-left h6 {
  font-size: 16px;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.deposit-giftcard-popup .deposit-robox-content .gift-left p {
  font-size: 18px;
  color: rgb(180, 157, 99);
  font-weight: bold;
}

.deposit-giftcard-popup .gift-left {
  padding: 20px 0px 20px 20px;
  z-index: 99;
}
.deposit-giftcard-popup .gift-box::before {
  background: url(../../assets/images/deposit/gift-box.png);
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9;
}
@media screen and (max-width: 1220px) {
  .deposit-popup .coin-boxes img {
    width: 45px;
  }
}
@media screen and (max-width: 767px) {
  .deposit-giftcard-popup .gift-card-section {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .deposit-giftcard-popup .deposit-robox-content .gift-left h6 {
    font-size: 12px;
  }
  .deposit-giftcard-popup .deposit-robox-content .gift-left p {
    font-size: 12px;
  }
  .deposit-giftcard-popup .deposit-robox-content .gift-left img {
    height: 15px;
  }
  .deposit-giftcard-popup .gift-box .gift-right p {
    font-size: 10px;
  }
  .deposit-popup .coin-boxes img {
    width: 45px;
  }
  .deposit-popup .deposit-box-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .deposit-popup .depsoit-popup-content {
    padding: 25px 25px;
  }
  .deposit-popup .depsoit-popup-content h6 {
    font-size: 20px;
  }
  .deposit-popup .deposit-boxes img {
    width: 80px;
  }
  .deposit-popup .coin-boxes p {
    font-size: 15px;
    margin-top: 15px;
  }
  .deposit-popup .coin-boxes {
    height: 125px;
  }
  .deposit-via-popup .right-deposit-via img {
    height: 135px;
  }
  .deposit-robox-popup .input-section {
    margin-top: 15px;
  }
  .deposit-robox-popup p {
    max-width: 100%;
    font-size: 12px;
  }
  .deposit-via-creditcard .deposit-credit-content {
    padding: 35px 40px;
  }
  .deposit-via-creditcard .kyc-verification-section .kyc-box {
    width: 48%;
  }
  .deposit-via-popup .right-deposit-via {
    width: 135px;
  }
  .deposit-via-popup .left-deposit-via {
    width: calc(100% - 140px);
  }
  .deposit-robox-popup .input-section,
  .deposit-robox-popup .input-section {
    max-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .deposit-giftcard-popup .gift-card-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .deposit-popup .coin-boxes img {
    width: 25px;
  }
  .deposit-popup .coin-boxes {
    height: 100px;
  }
  .deposit-popup .deposit-box-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .deposit-popup .depsoit-popup-content {
    padding: 25px 15px;
  }
  .deposit-popup .depsoit-popup-content h6 {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .deposit-popup .deposit-boxes img {
    width: 60px;
  }
  .deposit-popup .coin-boxes p {
    font-size: 13px;
    margin-top: 10px;
  }
  .deposit-via-popup .right-deposit-via img {
    height: 125px;
  }
  .deposit-via-creditcard .deposit-credit-content {
    padding: 35px 15px;
  }
  .deposit-via-creditcard .kyc-verification-section .kyc-box {
    width: 48%;
  }
  .deposit-popup .deposit-boxes {
    width: 48%;
    height: 135px;
  }
  .deposit-robox-popup .deposit-robox-content {
    padding: 35px 15px;
  }
  .deposit-via-popup .left-deposit-via {
    width: 100%;
    margin-bottom: 20px;
  }
  .deposit-via-popup .right-deposit-via {
    width: 100%;
    justify-content: center;
  }
  .deposit-via-popup .deposit-via-sectionn {
    flex-wrap: wrap;
  }
  .deposit-robox-popup p {
    width: 100%;
    max-width: 100%;
  }
  .deposit-robox-popup .deposit-robox-content h6 {
    font-size: 18px;
  }
  .deposit-via-popup .enter-value .form-field,
  .deposit-via-popup .enter-value span {
    margin: 10px 0px 0px;
    width: 100%;
  }
  .deposit-via-popup .enter-value span {
    text-align: center;
  }
  .deposit-robox-popup .input-section {
    max-width: 100%;
  }
  .deposit-via-popup .enter-value {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 30px;
  }
  .deposit-via-creditcard .kyc-verification-section .kyc-box p {
    font-size: 12px;
  }
  .deposit-via-creditcard .deposit-via-sectionn h6 {
    margin-bottom: 15px;
  }
  .deposit-via-creditcard .deposit-via-sectionn p {
    font-size: 12px;
  }
  .deposit-via-creditcard .form-check label {
    font-size: 12px;
  }
  .deposit-giftcard-popup .deposit-robox-content .gift-left h6 {
    font-size: 12px;
  }
  .deposit-giftcard-popup .deposit-robox-content .gift-left p {
    font-size: 12px;
  }
  .deposit-giftcard-popup .deposit-robox-content .gift-left img {
    height: 15px;
  }
  .deposit-giftcard-popup .gift-box .gift-right p {
    font-size: 10px;
  }

}
