.tower-page {
  background: #383d4d;
}

.tower-page {
  padding: 25px 0px;
}
.tower-title {
  position: relative;
  z-index: 99;
}
.tower-body {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.tower-heading h2 {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin: 0;
}
.cash-out {
  cursor: pointer;
}
.cash-out,
.tower-heading,
.tower-lost {
  border-width: 1px;
  border-color: rgb(24 104 5);
  border-style: solid;
  border-radius: 8px;
  background-color: rgb(24 104 5);
  text-align: center;
  width: 100%;
  margin: 0px auto;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -25px;
  max-width: 278px;
  height: 58px;
}
.tower-heading h2 {
  font-size: 20px;
  color: #0c0e23;
  margin: 0;
}
.cash-out h2 {
  font-size: 20px;
  color: rgb(168, 245, 92);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cash-out img {
  margin: 0px 9px;
}
.cash-out span {
  color: white;
}
.tower-lost h2 {
  font-size: 20px;
  color: rgb(255, 255, 255);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}
.tower-lost img {
  margin: 0px 9px;
}
.tower-heading {
  background: #51e2f5;
  border-color: #51e2f5;
}
.tower-lost {
  background: #920a0a;
  border-color: #920a0a;
}
.tower-lost span {
  color: #ffffff;
  font-weight: bold;
}
.tower-page .tower-game-section {
  width: calc(100% - 470px);
  margin-right: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: rgb(31 35 48);
}
.tower-main-box {
  border-radius: 8px;
  background-color: rgb(26 29 41);
  padding: 58px 25px 20px 25px;
  max-width: 700px;
  width: 100%;
  position: relative;
  margin: 0px auto;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.tower-list-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  grid-gap: 10px;
  margin-bottom: 10px;
}
.tower-box {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  text-align: center;
  padding: 11px 0px;
  width: 100%;
  border-radius: 6px;
  background-color:rgb(79 83 96);
  /* width: 210px; */
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tower-box h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tower-box img {
  margin-right: 8px;
  height: 35px;
}
.tower-body .roll-dice .btn,
.tower-body .roll-dice .btn:hover,
.tower-body .roll-dice .btn:focus,
.tower-body .roll-dice .btn-primary:not(:disabled):not(.disabled):active {
  max-width: 348px;
  height: 48px !important;
  width: 100%;
  font-size: 20px !important;
  font-weight: 600 !important;
}
.tower-body .roll-dice {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 35px;
}
.tower-bet-box {
  width: 450px;
  border-radius: 8px;
  background-color: #1f2330;
  padding: 45px 50px;
  position: relative;
}
.level-btn {
  border-width: 1px;
  border: 0.4px solid rgb(54, 57, 67) !important;
  border-style: solid;
  border-radius: 8px;
  background-color: rgb(26 29 41);
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
}
.level-btn .btn,
.level-btn .btn:hover,
.level-btn .btn:focus,
.level-btn .btn-primary:not(:disabled):not(.disabled):active {
  background: #363943;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: none;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  /* max-width: 148px; */
  width: 100%;
  height: 45px;
}
.level-btn .btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.tower-body .change-seed {
  position: unset;
  bottom: unset;
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

.tower-body .change-seed .btn,
.tower-body .change-seed .btn:hover,
.tower-body .change-seed .btn:focus,
.tower-body .change-seed .btn-primary:not(:disabled):not(.disabled):active {
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  padding: 11px 12px;
}
.tower-page .dice-tab-grid {
  margin-top: 80px;
}
.level-btn .active-level-btn,
.level-btn .active-level-btn:hover,
.level-btn .active-level-btn:focus {
  color: #51e2f5;
  background-color: rgb(12, 14, 35) !important;
  border-radius: 0px !important;
}
.level-btn .active-level-btn:first-child {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
.level-btn .active-level-btn:last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.level-btn button {
  background: transparent !important;
}
.tower-box.winner-tower-box img {
  margin: 0px;
}
.tower-box {
  cursor: pointer;
}
.lose-tower-game .tower-box {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  background: #23252b;
  opacity: 0.5;
}
.lose-tower-game .winner-tower-row .tower-box {
  border-radius: 6px;
  /* background-color: rgb(25, 27, 45); */
  background-color: rgb(25, 27, 45);
  /* opacity: 0.702; */
}
.tower-box.winner-tower-box {
  background: radial-gradient(
      100% 100% at 50% 0%,
      rgba(129, 240, 31, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(0deg, rgba(129, 240, 31, 0.25), rgba(129, 240, 31, 0.25)),
    #363943 !important;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.lose-tower-game .tower-box {
  background-color: rgb(31 35 48);
}
.tower-list-row.winner-tower-row:before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background: url("../../assets/images/tower/arrow-left.png");
  left: -25px;
  top: 15px;
  background-repeat: no-repeat;
}
.tower-list-row.winner-tower-row:after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  background: url("../../assets/images/tower/arrow-right.png");
  right: -31px;
  top: 15px;
  background-repeat: no-repeat;
}

.tower-list-row.winner-tower-row {
  position: relative;
}
.triangle-6 {
  width: 60px;
  height: 30px;
  border-top: solid 30px rgb(200, 30, 50);
  border-left: solid 30px transparent;
  border-right: solid 30px transparent;
  position: absolute;
}
.triangle.triangle1.triangle-6 {
  left: 8px;
  transform: rotate(-15deg);
  top: -10px;
  width: 15px;
  height: 20px;
  border-top: solid 29px #16181e;
  border-left: solid 16px transparent;
  border-right: solid 0px transparent;
  position: absolute;
}

.triangle.triangle2.triangle-6 {
  right: 40px;
  transform: rotate(35deg);
  top: -18px;
  width: 15px;
  height: 20px;
  border-top: solid 29px #16181e;
  border-left: solid 16px transparent;
  border-right: solid 0px transparent;
  position: absolute;
}
.triangle.triangle3.triangle-6 {
  right: -11px;
  transform: rotate(-209deg);
  bottom: 0px;
  width: 15px;
  height: 20px;
  border-top: solid 29px #1a1c22;
  border-left: solid 16px transparent;
  border-right: solid 0px transparent;
  position: absolute;
}
.bet-input.pdd-200 i {
  position: absolute;
  color: rgb(81, 226, 245);
  font-size: 20px;
  left: 17px;
}
.bet-input-btn button,
.bet-input-btn button:hover,
.bet-input-btn button:focus {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
  width: 30%;
  border: 0.4px solid rgb(54, 57, 67);
  border-radius: 8px;
  background-color: #1a1d29;
  height: 48px;
  margin-bottom: 25px;
}
.bet-input-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}
.bet-input .form-field {
  width: 100%;
}

/***** tower history  ************/
.dice-history .table thead th {
  border: none;
  font-weight: normal;
  line-height: 16px;
  padding: 16px 26px;
  font-size: 14px;
  color: rgb(81, 226, 245);
  font-weight: 500;
}
.dice-history .table thead {
  background-color: rgb(17, 19, 38);
}
.dice-history tbody,
.dice-history td,
.dice-history th,
.dice-history thead,
.dice-history tr {
  border: none !important;
}
.dice-history .table {
  color: #fff;
}
.dice-history tbody tr td:nth-child(1),
.dice-history tbody tr td:nth-child(2) {
  color: white !important;
}
.dice-history .table.table-striped tbody tr {
  border-bottom: 1px solid #363943;
}

.dice-history .table.table-striped tbody tr:last-child {
  border: none;
}

.dice-history .table.table-striped tbody tr:nth-of-type(even) {
  background-color: #1f2330  !important;
}
.dice-history .table-bordered td,
.dice-history .table-bordered th {
  border: none;
}

.dice-history .table-bordered td {
  font-weight: normal;
  line-height: 16px;
  text-align: left;
  padding: 10px 26px;
  vertical-align: middle;
  font-size: 14px;
  color: rgb(141, 142, 146) !important;
}

.dice-history .table.table-striped tbody tr td:nth-child(2) {
  color: #fff;
}

.dice-history .table.table-striped tbody tr td:first-child {
  color: #fff;
}

.dice-history .table-bordered td {
  padding: 17px 26px;
}

.dice-history .table.table-striped tbody tr td:last-child {
  color: #a8f55c;
}
.dice-history .table.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent !important;
}
.tower-page .dice-tab .nav-tabs {
  margin-bottom: 25px !important;
  overflow-y: hidden;
}
.tower-list-row.tower-list-four {
  grid-template-columns: repeat(4, minmax(0px, 1fr));
}
.tower-list-row {
  pointer-events: none;
  cursor: not-allowed;
}
.winner-tower-row {
  pointer-events: all !important;
}
.tower-list-row.winner-tower-row .tower-box {
  background: #51e2f5a6;
  transition: all ease 0.5s;
}
.tower-list-row.winner-tower-row .tower-box:hover {
  transform: translateY(-5px);
}
.lose-tower-game .win-box {
  background-color:rgb(5 131 133);
  opacity: 1;
}
.tower-box.win-box.red-box {
  background: #920a0a;
  opacity: 1 !important;
}
.tower-box.win-box.red-box img {
  animation: neon 2s infinite;
}
@keyframes neon {
  0% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(2.5);
  }

  100% {
    transform: scale(1.2);
  }
}
.disable-bet {
  opacity: 0.5;
  pointer-events: none;
}
.volume-btn {
  position: absolute;
  right: 23px;
  top: 12px;
}

.volume-btn i {
  color: #51e2f5;
  font-size: 30px;
  cursor: pointer;
}
button.disable-btn {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.6;
}
/********* media query ************/
@media screen and (max-width: 1660px) {
  .tower-page {
    padding: 15px 0px;
  }
  .tower-box {
    height: 48px;
  }
  .tower-box h6 {
    font-size: 13px;
  }
  .tower-box img {
    height: 35px;
  }
  .tower-list-row.winner-tower-row:before,
  .tower-list-row.winner-tower-row:after {
    height: 20px;
    width: 20px;
    background-size: contain;
  }
  .cash-out,
  .tower-heading,
  .tower-lost {
    height: 48px;
  }
  .tower-lost h2 {
    font-size: 15px;
  }
  .tower-main-box {
    max-width: 550px;
  }
  .tower-bet-box {
    width: 350px;
    padding: 30px 25px;
  }
  .tower-page .tower-game-section {
    width: calc(100% - 370px);
  }
  .tower-page .bet-input-btn button {
    height: 42px;
    margin-bottom: 20px;
  }
  .tower-body .roll-dice .btn,
  .tower-body .roll-dice .btn:hover,
  .tower-body .roll-dice .btn:focus,
  .tower-body .roll-dice .btn-primary:not(:disabled):not(.disabled):active {
    height: 40px !important;
    font-size: 16px !important;
  }
  .tower-page .form-field input {
    height: 42px;
  }
  .tower-page .bet-input i {
    top: 12px;
  }
}
@media screen and (max-width: 1220px) {
  .tower-bet-box {
    width: 250px;
    padding: 20px 15px;
  }
  .tower-page .tower-game-section {
    width: calc(100% - 250px);
  }
  .coins-img img {
    height: 70px;
    margin-top: -30px;
  }
  .winning-popup p {
    font-size: 30px;
  }
  .winning-popup h6 {
    font-size: 90px;
  }
  .tower-box {
    height: 40px;
  }
  .tower-main-box {
    padding: 40px 25px 20px 25px;
    border-radius: 8px;
  }
  .volume-btn i {
    font-size: 22px;
  }
  .tower-page {
    padding: 35px 0px;
  }
  .tower-page .tower-game-section {
    background-color: transparent;
    padding: 0px 0px;
  }
  .cash-out,
  .tower-heading,
  .tower-lost {
    max-width: 240px;
    height: 45px;
  }
}
@media screen and (max-width: 768px) {
  .dice-history .table-bordered td {
    white-space: nowrap;
  }
  .tower-main-box {
    border-radius: 8px !important;
  }
  .tower-box {
    height: 40px;
  }
  .tower-main-box {
    padding: 55px 25px 20px 25px;
  }
  .tower-page .tower-game-section {
    width: 100%;
    margin-right: 0px;
    padding: 20px 0px;
    background-color: transparent;
  }
  .bet-input-btn {
    justify-content: center;
  }
  .tower-bet-box {
    width: 100%;
    margin-top: 20px;
  }
  .tower-body {
    display: flex;
    flex-direction: column;
  }
  .tower-page .bet-input-btn button {
    height: 42px;
    margin-bottom: 20px;
    width: 18%;
    height: 36px;
    margin-bottom: 25px;
    margin-right: 15px;
  }
  .tower-page .tower-game-section {
    width: 100%;
  }
  .coins:before {
    background-size: 100% !important;
  }
  .coins-img img {
    height: 70px;
    margin-top: -30px;
  }
}
@media screen and (max-width: 480px) {
  .cash-out,
  .tower-heading,
  .tower-lost {
    height: 40px;
    width: 80%;
  }
  .tower-main-box {
    padding: 30px 10px 2px 10px;
  }
  .bet-input .bet-input-btn {
    flex-wrap: wrap;
    margin-top: 10px;
    width: 100%;
  }
  .bet-input .form-field {
    width: 100%;
  }
  .bet-input.pdd-200 {
    flex-wrap: wrap;
  }
  .tower-box {
    height: 45px;
  }
  .tower-body .roll-dice .btn,
  .tower-body .roll-dice .btn:hover,
  .tower-body .roll-dice .btn:focus,
  .tower-body .roll-dice .btn-primary:not(:disabled):not(.disabled):active {
    max-width: 240px;
    height: 50px !important;
    font-size: 15px !important;
  }
  .bet-input.pdd-200 i {
    left: 12px;
    top: 14px;
  }
  .bet-input .bet-input-btn button {
    margin: 0;
    margin-right: 10px;
  }
  .tower-list-row.winner-tower-row:after {
    width: 18px;
    height: 18px;
    right: -30px;
    background-size: contain;
  }
  .tower-list-row.winner-tower-row:before {
    width: 18px;
    height: 18px;
    left: -24px;
    background-size: contain;
  }
  .tower-box img {
    height: 14px;
  }
  .tower-page .bet-input-btn button {
    width: 25%;
    margin-right: 0px;
  }
  .bet-input-btn{
    column-gap: 15px;
  }
  .winning-popup p {
    font-size: 28px;
  }
  .winning-popup h6 {
    font-size: 90px;
  }
}

@media screen and (max-width: 375px) {
  .tower-box {
    height: 30px;
  }
  .tower-body .roll-dice .btn,
  .tower-body .roll-dice .btn:hover,
  .tower-body .roll-dice .btn:focus,
  .tower-body .roll-dice .btn-primary:not(:disabled):not(.disabled):active {
    max-width: 240px;
    height: 45px !important;
    font-size: 15px !important;
  }
  .bet-input .bet-input-btn button {
    width: 75px;
    height: 45px;
  }
  .tower-heading h2 {
    font-size: 15px;
  }
  .volume-btn i {
    font-size: 22px;
  }
  .winning-popup h6 {
    font-size: 55px;
  }
  .winning-popup h1 {
    font-size: 30px;
  }
}
